<template>
    <div>
        <b-card class=" mb-5" v-if="!isAuthenticated">
            <b-alert
                :show="!isAuthenticated"
                variant="danger"
                fade
                >Du har inte tillgång till denna sida</b-alert
            >
        </b-card>

        <b-card class="login-body mb-5" v-if="isAuthenticated && form === null">
            <b-alert show variant="danger">Biljett hittades ej</b-alert>
        </b-card>

        <b-card v-if="isAuthenticated && form !== null">
            <h3 class="text-center mt-4 mb-8">Evenemangsadmin</h3>
            <b-form>
                <b-row class="mt-4 mb-4">
                    <b-col lg="12">
                        <b-alert

                            :show="isCheckedIn"
                            variant="success"
                            fade
                            >Denna person är incheckad</b-alert
                        >
                        <b-alert

                            :show="!isCheckedIn"
                            variant="secondary"
                            fade
                            >Denna person är EJ incheckad</b-alert
                        >
                    </b-col>

                </b-row>
                <b-row>
                <b-form-group class="col-6" id="firstname-group" label="Förnamn" label-for="firstname">
                    <b-form-input
                    name="firstname"
                    placeholder="Förnamn"
                    v-model="form.firstname"
                    />
                </b-form-group>

                <b-form-group class="col-6" id="lastname-group" label="Efternamn" label-for="lastname">
                    <b-form-input
                    id="lastname"
                    name="lastname"
                    placeholder="Efternamn"
                    v-model="form.lastname"
                    />

                </b-form-group>
                </b-row>

                <b-row>
                <b-form-group class="col-12" id="email-group" label="E-post" label-for="email">
                    <b-form-input
                    name="email"
                    placeholder="Epost"
                    v-model="form.email"
                    />
                </b-form-group>
                </b-row>

                <b-row>
                <b-form-group class="col-12" id="phone-group" label="Telefon" label-for="phone">
                    <b-form-input
                    id="phone"
                    name="phone"
                    placeholder="Telefon"
                    v-model="form.phone"
                    />

                </b-form-group>
                </b-row>

                <b-row>
                <b-form-group class="col-12" id="tickets-group" label="Antal biljetter" label-for="tickets">
                    <b-form-input
                    id="tickets"
                    name="tickets"
                    placeholder="Antal biljetter"
                    v-model="form.tickets"
                    disabled
                    />

                </b-form-group>
                </b-row>
                <b-row>
                    <b-col col="6">
                        <b-button variant="primary" v-if="!isCheckedIn" @click="checkIn">Checka in</b-button>
                        <b-button variant="warning" v-if="isCheckedIn" @click="update">Uppdatera</b-button>
                    </b-col>
                    <b-col col="6" style="text-align: right;">
                        <b-button variant="danger" v-if="isCheckedIn" @click="remove">Ta bort</b-button>
                    </b-col>
                </b-row>
            </b-form>

        </b-card>

        <b-card class="login-body mb-5" v-if="isAuthenticated && false">
            <h3 class="text-center mt-4 mb-8">Sök deltagare</h3>

            <b-row>
                <b-form-group class="col-12" id="searchString-group" label-for="searchString">
                    <b-form-input
                    id="searchString"
                    name="searchString"
                    placeholder="Namn, e-post, telefon, medlemsnummer..."
                    v-model="searchString"
                    />

                </b-form-group>
            </b-row>

            <div class="table-responsive">
                <table
                class="table table-head-custom table-vertical-center table-head-bg table-borderless"
                >
                <thead>
                    <tr class="text-left">
                    <th>Namn</th>
                    <th>E-post</th>
                    <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(row, i) in searchResult">
                    <tr v-bind:key="i">
                        <td>
                        <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{
                            row.firstname + ' ' + row.lastname
                        }}</span>
                        </td>
                        <td>
                        <span class="text-dark-75 d-block font-size-lg">{{
                            row.email
                        }}</span>
                        </td>
                        <td>
                        <span class="text-dark-75 d-block font-size-lg">{{
                            row.is_checked_in
                        }}</span>
                        </td>

                    </tr>
                    </template>
                </tbody>
                </table>
            </div>
        </b-card>

    </div>

</template>

<style lang="scss" scoped>
.login-body {
  max-width: 1024px;
  width: 100%;
  .login-form {
    padding: 2rem 1.5rem 2rem 1.5rem;
    .btn-login {
      font-size: 1.2rem;
      font-weight: 500;
      padding: 1rem 3rem;
      background-color: #5d78ff;
      border-color: #5d78ff;
      &:hover {
        background-color: #3758ff;
        border-color: #2a4eff;
      }
    }
  }
}
</style>

<script>
import axios from 'axios';

import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {

  name: 'event-checkin',
  data() {
    return {
        token: null,
        isCheckedIn: false,
        form: null,
        searchString: null,
        searchResult: []
    }
  },
  mixins: [ toasts ],

  mounted() {
    this.token = this.$route.params.token;

    if (this.isAuthenticated) {
      this.getCheckin(this.token);
    }
  },
  methods: {
    putCheckin(data) {
      axios
        .put(`/events/checkin/${this.token}`, data)
        .then(res => {
          this.isCheckedIn = res.data.is_checked_in;
          this.toastr('success', this.$t('COMMON.OK'), 'Uppdaterat');
        })
        .catch(err => {
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte uppdatera');
          console.error(err);
        });
    },
    getCheckin(token) {
      axios
        .get(`/events/checkin/${token}`)
        .then(res => {

          this.form = res.data.member_event.member ? res.data.member_event.member : res.data.member_event.person;
          this.form.tickets = res.data.tickets;
          this.isCheckedIn = res.data.is_checked_in;
        })
        .catch(err => {
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta data');
          console.error(err);
        });
    },
    searchTicket(str) {
      axios
        .get(`/events/searchticket?str=${str}`)
        .then(res => {

        })
        .catch(err => {
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta data');
          console.error(err);
        });
    },
    checkIn() {
      this.form.action = 'checkin';
      this.putCheckin(this.form)
    },
    update() {
      this.form.action = 'update';
      this.putCheckin(this.form);
    },
    remove() {
      this.form.action = 'remove';
      this.putCheckin(this.form);
    },

  },
  computed: {
    ...mapGetters(['isAuthenticated']),
  }
};
</script>
